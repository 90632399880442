import React, { useEffect } from "react";
import "./AboutUs.css";

// Aos
import Aos from "aos";
import "aos/dist/aos.css";

// React-icons
import { FaMapMarked } from "react-icons/fa";
import { FaRegFaceSmile } from "react-icons/fa6";
import { MdConstruction } from "react-icons/md";
import { MdEngineering } from "react-icons/md";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaToolbox } from "react-icons/fa";
import { IoGiftSharp } from "react-icons/io5";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

// Initialization for ES Users
import { Carousel, initTWE } from "tw-elements";

//Imported Images
import img1 from "../../Assets/img1.jpg";
import img2 from "../../Assets/img2.jpg";
import img3 from "../../Assets/img3.jpg";
import img4 from "../../Assets/img4.jpg";
import img5 from "../../Assets/img5.jpg";
import img6 from "../../Assets/img6.jpg";
import img7 from "../../Assets/img7.jpg";
import img8 from "../../Assets/img8.jpg";
import img9 from "../../Assets/img9.jpg";
import img10 from "../../Assets/img10.jpg";
import img11 from "../../Assets/img11.jpg";
import img12 from "../../Assets/img12.jpg";
import img13 from "../../Assets/img13.jpg";
import img14 from "../../Assets/img14.jpg";
import img15 from "../../Assets/img15.jpg";
import img16 from "../../Assets/img16.jpg";
import img17 from "../../Assets/img17.jpg";

initTWE({ Carousel });


const AboutUs = () => {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);
  const sliderImage = [
    {
      id: 1,
      img: img1,
    },
    {
      id: 2,
      img: img2,
    },
    {
      id: 3,
      img: img3,
    },
    {
      id: 4,
      img: img4,
    },
    {
      id: 5,
      img: img5,
    },
    {
      id: 6,
      img: img6,
    },
    {
      id: 7,
      img: img7,
    },
    {
      id: 8,
      img: img8,
    },
    {
      id: 9,
      img: img9,
    },
    {
      id: 10,
      img: img10,
    },
    {
      id: 11,
      img: img11,
    },
    {
      id: 12,
      img: img12,
    },
    {
      id: 13,
      img: img13,
    },
    {
      id: 14,
      img: img14,
    },
    {
      id: 15,
      img: img15,
    },
    {
      id: 16,
      img: img16,
    },
    {
      id: 17,
      img: img17,
    },
  ];
  
  return (
    <>
      <section data-aos="fade-up" class="">
        <div class="py-12 bg-white">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="lg:text-center">
              <h2 class="font-semibold text-lg mb-4 bg-orange-100 text-orange-800 px-4 py-2 rounded-lg md:w-64 md:mx-auto tracking-widest text-black uppercase title-font">
                لماذا تختارنا؟
              </h2>
              <p class="font-heading mt-2 text-3xl leading-8 font-semibold tracking-tight text-gray-900 sm:text-4xl">
                نحن خبراء في مجال المقاولات والبناء. نضمن جودة عالية وسرعة
                تنفيذ.
              </p>
              <p class="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                نحن ندير جميع جوانب المشروع، من التخطيط إلى التنفيذ، ونضمن رضا
                العملاء الكامل.
              </p>
            </div>

            <div class="mt-10">
              <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500">
                      <MdConstruction className="text-4xl text-rose-600" />
                    </div>
                    <p class="font-heading  relative right-12 ml-16 text-lg leading-6 font-bold text-gray-700">
                      معدات حديثة
                    </p>
                  </dt>
                  <dd class="mt-2 ml-16  relative right-12 text-base text-gray-500">
                    نستخدم أحدث المعدات والتقنيات لضمان تنفيذ المشروع بأعلى
                    معايير الجودة.
                  </dd>
                </div>
                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500">
                      <MdEngineering className="text-4xl text-rose-600" />
                    </div>
                    <p class="font-heading relative right-12 ml-16 text-lg leading-6 font-bold text-gray-700">
                      فريق متخصص
                    </p>
                  </dt>
                  <dd class="mt-2 ml-16 relative right-12 text-base text-gray-500">
                    فريقنا من المهندسين والفنيين ذوي الخبرة يضمنون تنفيذ المشروع
                    بكل دقة وكفاءة.
                  </dd>
                </div>
                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500">
                      <FaMoneyBillWave className="text-4xl text-rose-600" />
                    </div>
                    <p class="font-heading relative right-12 ml-16 text-lg leading-6 font-bold text-gray-700">
                      تكلفة معقولة
                    </p>
                  </dt>
                  <dd class="mt-2 ml-16 relative right-12 text-base text-gray-500">
                    نقدم أسعاراً تنافسية دون المساومة على جودة العمل.
                  </dd>
                </div>
                <div class="relative">
                  <dt>
                    <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500">
                      <FaToolbox className="text-4xl text-rose-600" />
                    </div>
                    <p class="font-heading relative right-12 ml-16 text-lg leading-6 font-bold text-gray-700">
                      دعم مستمر
                    </p>
                  </dt>
                  <dd class="mt-2 ml-16 relative right-12 text-base text-gray-500">
                    نقدم دعم مستمر خلال جميع مراحل المشروع لضمان سير العمل
                    بسلاسة.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" class="text-gray-950 font-bold body-font">
        <div class="container px-5 py-12 mx-auto">
          <div class="flex flex-wrap text-center justify-center">
            <div class="p-4 md:w-1/4 sm:w-1/2">
              <div class="px-4 py-6 transform transition duration-500 hover:scale-110">
                <div class="flex justify-center">
                  <img
                    src="https://image2.jdomni.in/banner/13062021/3E/57/E8/1D6E23DD7E12571705CAC761E7_1623567977295.png?output-format=webp"
                    class="w-32 mb-3"
                  />
                </div>
                <h2 class="title-font font-regular text-2xl text-gray-900">
                  أقل وأفضل أسعار وخصم 8% بمناسبة مرور "8" عام من الخبرة
                </h2>
              </div>
            </div>

            <div class="p-4 md:w-1/4 sm:w-1/2">
              <div class="px-4 py-6 transform transition duration-500 hover:scale-110">
                <div class="flex justify-center">
                  <img
                    src="https://image3.jdomni.in/banner/13062021/16/7E/7E/5A9920439E52EF309F27B43EEB_1623568010437.png?output-format=webp"
                    class="w-32 mb-3"
                  />
                </div>
                <h2 class="title-font font-regular text-2xl text-gray-900">
                  كفاءة الوقت وأسرع خدمة
                </h2>
              </div>
            </div>

            <div class="p-4 md:w-1/4 sm:w-1/2">
              <div class="px-4 py-6 transform transition duration-500 hover:scale-110">
                <div class="flex justify-center">
                  <img
                    src="https://image3.jdomni.in/banner/13062021/EB/99/EE/8B46027500E987A5142ECC1CE1_1623567959360.png?output-format=webp"
                    class="w-32 mb-3"
                  />
                </div>
                <h2 class="title-font font-regular text-2xl text-gray-900">
                  عملاء متخصصون و ذو خبرة عالية في المجال
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className="mb-28">
        <div className="title relative text-center mb-16 text-4xl font-bold">
          <h1>أعمالنا</h1>
          <p className="w-24 h-1 rounded-sm top-5 bg-rose-600 mx-auto"></p>
        </div>
        <div className="slider max-w-screen-xl mx-auto px-4">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }}
            loop={true}>
            {sliderImage.map((image) => (
              <SwiperSlide>
                <img
                  src={image.img}
                  alt="..."
                  className="w-full h-auto rounded-md shadow-2xl bg-[#00000035] p-3"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      <section data-aos="fade-up" class="m-4 md:m-8 bg-gray-50 text-gray-950">
        <div class="container mx-auto p-4 my-6 space-y-2 text-center">
          <h2 class="text-4xl text-center font-bold">دع الأرقام تتكلم</h2>
          <div class="flex justify-center">
            <p class="w-[100px] h-[4px] rounded-sm bg-rose-600 mt-2"></p>
          </div>
        </div>
        <div class="container mx-auto grid gap-4 sm:grid-cols-2 lg:grid-cols-3 justify-center">
          <div class="flex flex-col items-center p-4">
            <IoGiftSharp class="text-6xl text-rose-600" />
            <h3 class="my-3 text-3xl font-bold">8%</h3>
            <div class="space-y-1 leading-tight text-center">
              <h2 class="text-2xl">خصم 8% بمناسبة مرور 8 أعوام من الخبرة</h2>
            </div>
          </div>
          <div class="flex flex-col items-center p-4">
            <FaRegFaceSmile class="text-6xl text-rose-600" />
            <h3 class="my-3 text-3xl font-bold">
              <strong>+</strong>590
            </h3>
            <div class="space-y-1 leading-tight text-center">
              <h2 class="text-2xl">العملاء</h2>
            </div>
          </div>
          <div class="flex flex-col items-center p-4">
            <FaMapMarked class="text-6xl text-rose-600" />
            <h3 class="my-3 text-3xl font-bold">3</h3>
            <div class="space-y-1 leading-tight text-center">
              <h2 class="text-2xl">الأفرع</h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
