import React from "react";
import { Link } from "react-router-dom";

// React-Icons

//Images
import Logo2 from "../Assets/Logo2.png";

const Footer = () => {
  return (
    <>
      <footer className="w-full py-14">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <Link
              to="/"
              className="flex flex-col sm:flex-row items-center justify-center mb-8">
              <img
                src={Logo2}
                className="mr-3 w-24 sm:w-24 md:w-32 lg:w-48 h-auto transition-all duration-300"
                alt="Logo"
              />
              <h1 className="text-xl font-bold sm:text-2xl lg:text-3xl text-gray-800 dark:text-gray-900">
                الصعيدي للمقاولات العامة والتوريدات
              </h1>
            </Link>
            <ul className="text-lg flex font-medium items-center justify-center flex-col md:flex-row md:gap-8 transition-all duration-500 py-8 mb-10 border-b border-gray-200">
              <li>
                <Link to="/" className="text-gray-800 hover:text-gray-900">
                  الرئيسية
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="text-gray-800 hover:text-gray-900">
                  الخدمات
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutUs"
                  className="text-gray-800 hover:text-gray-900">
                  عن الشركة
                </Link>
              </li>
              <li>
                <Link
                  to="/contactUs"
                  className="text-gray-800 hover:text-gray-900">
                  تواصل معنا
                </Link>
              </li>
            </ul>
            <span className="text-lg text-gray-500 text-center block">
              ©الصعيدي
              جميع الحقوق محفوظة 2024.
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
