import React, { useEffect } from "react";

// Aos
import Aos from "aos";
import "aos/dist/aos.css";

const ContactUs = () => {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);
  return (
    <>
      <section data-aos="fade-up" className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="max-w-2xl lg:max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">
              فرع القاهرة
            </h2>
            <p className="w-[100px] h-[4px] rounded-sm top-[20px] bg-rose-600 relative left-[-44%]"></p>
          </div>
          <div className="mt-16 lg:mt-20">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="rounded-lg overflow-hidden">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d110446.86996836035!2d30.901672349746582!3d29.973709239842106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14585d616ee0b56d%3A0xd2452e3bb23875!2s4th%20District%2C%206th%20of%20October%20City%2C%20Giza%20Governorate%2C%20Egypt!5e0!3m2!1sen!2sus!4v1625242387269!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"></iframe>
              </div>
              <div>
                <div className="max-w-full mx-auto rounded-lg overflow-hidden">
                  <div className="px-6 py-4">
                    <h3 className="text-2xl font-bold text-gray-900">
                      عنواننا
                    </h3>
                    <p className="mt-1 text-xl text-gray-900 font-medium">
                      9 شارع الياسمين - قطعة 1880 - الحي الرابع - المجاورة
                      الثامنة - 6اكتوبر - الجيزة
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-6 py-4">
                    <h3 className="text-xl font-medium text-gray-900">
                      تواصل علي
                    </h3>
                    <p className="mt-1 text-gray-950 font-bold">
                      ايميل: elsaidi.contracting2016@gmail.com
                    </p>
                    <p className="mt-1 text-gray-950 font-bold">
                      واتساب : 01129494349
                    </p>
                    <p className="mt-1 text-gray-950 font-bold">
                      جوال : 01129494349
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="max-w-2xl lg:max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-900">
              فرع الأقصر (الفرع الرئيسي)
            </h2>
            <p className="w-[100px] h-[4px] rounded-sm top-[20px] bg-rose-600 relative left-[-44%]"></p>
          </div>
          <div className="mt-16 lg:mt-20">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="rounded-lg overflow-hidden">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3437.4968956711266!2d32.55455771512494!3d25.293231283957904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14492138ae02c409%3A0x302b1dcb7dd17613!2z2YLYr9mK2YMg2KfZhNmE2YLYr9in2LE!5e0!3m2!1sen!2seg!4v1690542697283!5m2!1sen!2seg"
                  width="100%"
                  height="480"
                  style={{ border: "0" }}
                  loading="lazy"></iframe>
              </div>
              <div>
                <div className="max-w-full mx-auto rounded-lg overflow-hidden">
                  <div className="px-6 py-4">
                    <h3 className="text-2xl font-bold text-gray-900">
                      عنواننا
                    </h3>
                    <p className="mt-1 text-gray-900 font-medium">
                      بجوار المرور - مركز اسنا - الأقصر
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-6 py-4">
                    <h3 className="text-xl font-medium text-gray-900">
                      تواصل علي
                    </h3>
                    <p className="mt-1 text-gray-950 font-bold">
                      ايميل: elsaidi.contracting2016@gmail.com
                    </p>
                    <p className="mt-1 text-gray-950 font-bold">
                      واتساب : 01129494349
                    </p>
                    <p className="mt-1 text-gray-950 font-bold">
                      جوال : 01012826212
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
