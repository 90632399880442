import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Home.css";

// React-icons

//Imported Images
import ser_1 from "../../Assets/ser_1.png";
import ser_2 from "../../Assets/ser_2.png";
import ser_3 from "../../Assets/ser_3.png";
import img1 from "../../Assets/img1.jpg";
import img2 from "../../Assets/img2.jpg";
import img5 from "../../Assets/img5.jpg";
import img8 from "../../Assets/img8.jpg";
import img10 from "../../Assets/img10.jpg";
import img11 from "../../Assets/img11.jpg";
import img12 from "../../Assets/img12.jpg";
import img13 from "../../Assets/img13.jpg";
import img14 from "../../Assets/img14.jpg";
import img15 from "../../Assets/img15.jpg";
import img16 from "../../Assets/img16.jpg";
import img17 from "../../Assets/img17.jpg";

// Aos
import Aos from "aos";
import "aos/dist/aos.css";

const Home = () => {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  const cards = [
    { img: img10, title: "مشروع بناء", description: "تنفيذ مشروع بناء حديث" },
    {
      img: img11,
      title: "تطوير بنية تحتية",
      description: "تحسين وتطوير البنية التحتية",
    },
    {
      img: img12,
      title: "تصميم داخلي",
      description: "تصميم داخلي متطور وعصري",
    },
    {
      img: img13,
      title: "إدارة المشاريع",
      description: "إدارة المشاريع باحترافية",
    },
    {
      img: img14,
      title: "خدمات استشارية",
      description: "خدمات استشارية في مجال البناء",
    },
    {
      img: img15,
      title: "مشاريع تجارية",
      description: "تنفيذ مشاريع تجارية كبرى",
    },
    { img: img16, title: "أعمال تجديد", description: "تجديد وترميم المباني" },
    { img: img17, title: "خدمات هندسية", description: "خدمات هندسية متكاملة" },
  ];

  return (
    <>
      <section data-aos="fade-up" className="bg-white mt-10 p-8">
        <div className="title text-center mb-8">
          <h1 className="text-3xl text-gray-900">أعمالنا السابقة</h1>
          <p className="text-gray-700 text-lg">
            نقدم العديد من المشاريع في عدة مجالات داخل مصر
          </p>
        </div>

        <div class="py-4 px-2 mx-auto max-w-screen-xl sm:py-4 lg:px-6">
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">
            <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-full flex flex-col">
              <Link
                to="/treatments"
                class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow">
                <img
                  src={img1}
                  alt="img"
                  class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                />
                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                <h3 class="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                  محطات المعالجة
                </h3>
              </Link>
            </div>
            <div class="col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">
              <Link
                to="/bridges"
                class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4">
                <img
                  src={img8}
                  alt="img"
                  class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                />
                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                <h3 class="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                  الطرق والكباري
                </h3>
              </Link>
              <div class="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
                <Link
                  to="/buildings"
                  class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40">
                  <img
                    src={img5}
                    alt="img"
                    class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                  />
                  <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                  <h3 class="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                    مباني
                  </h3>
                </Link>
                <Link
                  to="/process"
                  class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40">
                  <img
                    src={img11}
                    alt="img"
                    class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                  />
                  <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                  <h3 class="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                    معدات العمليات
                  </h3>
                </Link>
              </div>
            </div>
            <div class="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">
              <Link
                to="/infrastructure"
                class="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow">
                <img
                  src={img2}
                  alt="img"
                  class="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                />
                <div class="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                <h3 class="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                  البنية التحتية
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className="heroSec relative mt-24">
        <div className="absolute inset-0 bg-gray-900/75 sm:bg-transparent sm:from-gray-900/95 sm:to-gray-900/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>

        <div className="relative top-[-50px] flex items-center justify-center h-screen mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:px-8">
          <div className="max-w-xl text-center">
            <h1
              className="text-3xl font-extrabold text-rose-600 sm:text-5xl"
              style={{ lineHeight: "1.4" }}>
              الصعيدي... 8 أعوام من الخبرة
            </h1>

            <p className="mt-4 max-w-lg text-white sm:text-xl/relaxed">
              يصنف الصعيدي من بين أكبر مجموعات البناء ذات الخدمة الكاملة
              والأعمال المدنية وتنفذ المشاريع المعقدة...
            </p>

            <div className="mt-8 flex flex-wrap gap-4 justify-center">
              <Link
                to="/aboutUs"
                className="block w-full rounded bg-white px-8 py-2 text-lg font-semibold text-rose-600 shadow hover:text-rose-700 focus:outline-none focus:ring active:text-rose-500 sm:w-auto">
                أعرف أكثر
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className="mt-32 bg-[#ebfaff]">
        <div className="h-full flex w-full justify-center items-center dark:bg-[#ebfaff] p-2">
          <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-4 md:p-2 xl:p-5">
            {cards.map((card, index) => (
              <div
                key={index}
                className="relative bg-white border rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 transform transition duration-500 hover:scale-105 w-full h-96">
                <div className="p-2 flex justify-center items-center h-2/3">
                  <a href="#">
                    <img
                      className="rounded-md card-image w-full h-full object-cover"
                      src={card.img}
                      loading="lazy"
                      alt={card.title}
                      style={{ height: "250px", width: "400px" }} // تحديد ارتفاع وعرض الصورة
                    />
                  </a>
                </div>
                <div className="px-4 pb-3 h-1/3">
                  <div>
                    <a href="#">
                      <h5 className="text-xl font-semibold tracking-tight hover:text-violet-800 dark:hover:text-violet-300 text-gray-900 dark:text-white">
                        {card.title}
                      </h5>
                    </a>
                    <p className="antialiased text-gray-600 dark:text-gray-400 text-sm break-all">
                      {card.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        className="p-4 lg:p-8 bg-gray-800 text-gray-100"
        style={{ margin: "50px 0" }}>
        <div data-aos="fade-up" className="text-center mb-8">
          <h1 className="text-4xl font-bold mb-2 sm:text-5xl">
            السجل التجاري الخاص بنا
          </h1>
          <p className="text-lg sm:text-xl mt-5">إنجازات وطموحات لا حدود لها</p>
        </div>

        <div className="container mx-auto space-y-12">
          <div
            data-aos="fade-up"
            className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row">
            <img src={ser_1} alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="flex flex-col justify-center flex-1 p-6 bg-gray-900">
              <span className="text-xs uppercase text-gray-400">
                م/ أحمد عاطف احمد
              </span>
              <h3 className="text-3xl font-bold">دائما نسعي لأجلكم</h3>
              <p className="my-6 text-gray-400">
                شركة الصعيدي ذو خبرة عالية في المجال , دائما نعمل لنقدم افضل عمل
                لكم بأفضل جودة.
              </p>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row-reverse">
            <img src={ser_2} alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="flex flex-col justify-center flex-1 p-6 bg-gray-900">
              <span className="text-xs uppercase text-gray-400">
                م/ أحمد عاطف احمد
              </span>
              <h3 className="text-3xl font-bold">دائما نسعي لأجلكم</h3>
              <p className="my-6 text-gray-400">
                شركة الصعيدي ذو خبرة عالية في المجال , دائما نعمل لنقدم افضل عمل
                لكم بأفضل جودة.
              </p>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row">
            <img src={ser_3} alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="flex flex-col justify-center flex-1 p-6 bg-gray-900">
              <span className="text-xs uppercase text-gray-400">
                م/ أحمد عاطف احمد
              </span>
              <h3 className="text-3xl font-bold">دائما نسعي لأجلكم </h3>
              <p className="my-6 text-gray-400">
                شركة الصعيدي ذو خبرة عالية في المجال , دائما نعمل لنقدم افضل عمل
                لكم بأفضل جودة.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
