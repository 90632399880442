import React, { useEffect } from "react";

// Aos
import Aos from "aos";
import "aos/dist/aos.css";

// Imported Images
import img1 from "../../Assets/img20.jpg";
import img2 from "../../Assets/img14.jpg";

const Services = () => {
   useEffect(() => {
     Aos.init({
       duration: 1500,
     });
   }, []);
  
  return (
    <>
      <br />
      <section data-aos="fade-up" class="w-full mx-auto py-10 bg-gray-50 dark:bg-gray-900 dark:text-white">
        <div class="w-fit pb-1 px-2 mx-4 rounded-md text-2xl font-semibold border-b-2 border-blue-600 dark:border-b-2 dark:border-yellow-600">
          الخدمات
        </div>

        <div class="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-4">
          <div class="lg:w-[50%] xs:w-full">
            <img
              class="lg:rounded-t-lg sm:rounded-sm xs:rounded-sm"
              src={img1}
              alt="Construction Image"
            />
          </div>
          <div class="lg:w-[50%] sm:w-full xs:w-full bg-gray-100 dark:bg-gray-900 dark:text-gray-400 md:p-4 xs:p-0 rounded-md">
            <h2 class="text-3xl font-semibold text-gray-900 dark:text-white">
              شركة الصعيدي للمقاولات العامة والتوريدات
            </h2>
            <p class="text-md mt-4">
              نحن نقدم مجموعة واسعة من الخدمات في مجال المقاولات العامة
              والتوريدات، مع خبرة عميقة في إنجاز المشاريع الكبيرة والصغيرة
              بنجاح. نحن ملتزمون بالجودة العالية والدقة في التنفيذ.
            </p>
          </div>
        </div>

        <div class="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-6">
          <div class="lg:w-[50%] xs:w-full bg-gray-100 dark:bg-gray-900 dark:text-gray-400 md:p-4 xs:p-0 rounded-md">
            <h2 class="text-3xl font-semibold text-gray-900 dark:text-white">
              مشاريعنا
            </h2>

            <p class="text-md mt-4">
              نفخر بإنجازاتنا المتعددة في مجال الإنشاءات والبناء، حيث قمنا
              بتنفيذ العديد من المشاريع البارزة التي تميزت بالجودة والإتقان. نحن
              نسعى دائمًا لتقديم الأفضل لعملائنا.
            </p>
          </div>
          <div class="md:block sm:hidden xs:hidden lg:w-[50%] xs:w-full">
            <img
              class="lg:rounded-t-lg xs:rounded-sm w-full h-[80%]"
              src={img2}
              alt="img"
            />
          </div>
        </div>
      </section>

      <br />
      <br />

      <section data-aos="fade-up" class="bg-white w-full">
        <div class="px-4 py-8 mx-auto max-w-screen-xl sm:py-16 lg:px-6 xl:px-0 dark:bg-gray-100">
          <div class="mx-auto mb-8 max-w-screen-md text-center md:mb-16">
            <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 md:text-4xl">
              دع الأرقام تتكلم
            </h2>
          </div>
          <div class="mb-4 space-y-4 md:grid md:grid-cols-3 md:gap-4 xl:gap-16 md:space-y-0 md:mb-8">
            <div class="flex justify-center items-center p-6 text-center bg-gray-100 rounded xl:p-12 dark:bg-gray-800">
              <div class="text-gray-900 dark:text-white">
                <p class="mb-2 text-4xl font-extrabold md:text-5xl">3</p>
                <h3 class="mb-2 text-xl font-semibold">الفروع</h3>
                <p class="font-light text-gray-500 dark:text-gray-400">
                  فروع شركة الصعيدي للمقاولات العامة والتوريدات
                </p>
              </div>
            </div>

            <div class="flex justify-center items-center p-6 text-center bg-gray-100 rounded xl:p-12 dark:bg-gray-800">
              <div class="text-gray-900 dark:text-white">
                <p class="mb-2 text-4xl font-extrabold md:text-5xl">+590</p>
                <h3 class="mb-2 text-xl font-semibold">الذين يعملون من اجلك</h3>
                <p class="font-light text-gray-500 dark:text-gray-400">
                  عملائنا ذو خبرة عالية في المجال
                </p>
              </div>
            </div>

            <div class="flex justify-center items-center p-6 text-center bg-gray-100 rounded xl:p-12 dark:bg-gray-800">
              <div class="text-gray-900 dark:text-white">
                <p class="mb-2 text-4xl font-extrabold md:text-5xl">8%</p>
                <h3 class="mb-2 text-xl font-semibold">خصم 8% بمناسبة مرور 8 أعوام من الخبرة</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <br />
      <br />

      <section data-aos="fade-up" class="overflow-hidden bg-white py-8 sm:py-16">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div class="lg:pr-8 lg:pt-4">
              <div class="lg:max-w-lg">
                <h2 class="text-base font-semibold leading-7 text-indigo-600">
                  تنفيذ أسرع
                </h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  خدمات مقاولات شاملة
                </p>
                <p class="mt-6 text-lg leading-8 text-gray-600">
                  نقدم خدمات مقاولات متكاملة تتيح لك تحسين وتوسيع عمليات البناء
                  والصيانة الخاصة بك.
                </p>
                <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  <div class="relative pl-9">
                    <dt class="inline font-semibold text-gray-900">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        class="absolute left-1 top-1 h-5 w-5 text-indigo-600">
                        <path d="M3.196 12.87l-.825.483a.75.75 0 000 1.294l7.25 4.25a.75.75 0 00.758 0l7.25-4.25a.75.75 0 000-1.294l-.825-.484-5.666 3.322a2.25 2.25 0 01-2.276 0L3.196 12.87z"></path>
                        <path d="M3.196 8.87l-.825.483a.75.75 0 000 1.294l7.25 4.25a.75.75 0 00.758 0l7.25-4.25a.75.75 0 000-1.294l-.825-.484-5.666 3.322a2.25 2.25 0 01-2.276 0L3.196 8.87z"></path>
                        <path d="M10.38 1.103a.75.75 0 00-.76 0l-7.25 4.25a.75.75 0 000 1.294l7.25 4.25a.75.75 0 00.76 0l7.25-4.25a.75.75 0 000-1.294l-7.25-4.25z"></path>
                      </svg>
                      إدارة المشاريع
                    </dt>
                    <dd class="inline">
                      نقدم إدارة متكاملة للمشاريع تتيح لك التحكم الكامل في جميع
                      مراحل البناء.
                    </dd>
                  </div>
                  <div class="relative pl-9">
                    <dt class="inline font-semibold text-gray-900">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        class="absolute left-1 top-1 h-5 w-5 text-indigo-600">
                        <path
                          fill-rule="evenodd"
                          d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
                          clip-rule="evenodd"></path>
                      </svg>
                      فريق عمل مؤهل
                    </dt>
                    <dd class="inline">
                      فريق من المهندسين والفنيين المؤهلين لضمان تنفيذ المشروع
                      بأعلى جودة.
                    </dd>
                  </div>
                  <div class="relative pl-9">
                    <dt class="inline font-semibold text-gray-900">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        class="absolute left-1 top-1 h-5 w-5 text-indigo-600">
                        <path
                          fill-rule="evenodd"
                          d="M14.5 10a4.5 4.5 0 004.284-5.882c-.105-.324-.51-.391-.752-.15L15.34 6.66a.454.454 0 01-.493.11 3.01 3.01 0 01-1.618-1.616.455.455 0 01.11-.494l2.694-2.692c.24-.241.174-.647-.15-.752a4.5 4.5 0 00-5.873 4.575c.055.873-.128 1.808-.8 2.368l-7.23 6.024a2.724 2.724 0 103.837 3.837l6.024-7.23c.56-.672 1.495-.855 2.368-.8.096.007.193.01.291.01zM5 16a1 1 0 11-2 0 1 1 0 012 0z"
                          clip-rule="evenodd"></path>
                        <path d="M14.5 11.5c.173 0 .345-.007.514-.022l3.754 3.754a2.5 2.5 0 01-3.536 3.536l-4.41-4.41 2.172-2.607c.052-.063.147-.138.342-.196.202-.06.469-.087.777-.067.128.008.257.012.387.012zM6 4.586l2.33 2.33a.452.452 0 01-.08.09L6.8 8.214 4.586 6H3.309a.5.5 0 01-.447-.276l-1.7-3.402a.5.5 0 01.093-.577l.49-.49a.5.5 0 01.577-.094l3.402 1.7A.5.5 0 016 3.31v1.277z"></path>
                      </svg>
                      دعم تقني
                    </dt>
                    <dd class="inline">
                      نقدم دعم تقني متواصل لضمان استمرارية العمل وسلاسة
                      العمليات.
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <img
src={img1}              alt="img"
              class="w-[30rem] ml-5 max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
