import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// Aos
import Aos from "aos";
import "aos/dist/aos.css";

// Imported Images
import img1 from "../../../Assets/img1.jpg";
import img2 from "../../../Assets/img2.jpg";
import img3 from "../../../Assets/img3.jpg";
import img4 from "../../../Assets/img4.jpg";
import img5 from "../../../Assets/img5.jpg";
import img6 from "../../../Assets/img6.jpg";
import img7 from "../../../Assets/img7.jpg";
import img8 from "../../../Assets/img8.jpg";
import img9 from "../../../Assets/img9.jpg";
import img10 from "../../../Assets/img10.jpg";
import { MdOutlineWorkspacePremium } from "react-icons/md";

const bridges = [
  {
    id: 1,
    image: img1,
    title: "أعمالنا الخاصة للطرق و الكباري",
  },
  {
    id: 2,
    image: img2,
    title: "أعمالنا الخاصة للطرق و الكباري",
  },
  {
    id: 3,
    image: img3,
    title: "أعمالنا الخاصة للطرق و الكباري",
  },
  {
    id: 4,
    image: img4,
    title: "أعمالنا الخاصة للطرق و الكباري",
  },
  {
    id: 5,
    image: img5,
    title: "أعمالنا الخاصة للطرق و الكباري",
  },
  {
    id: 6,
    image: img6,
    title: "أعمالنا الخاصة للطرق و الكباري",
  },
];

const gallery = [
  {
    id: 1,
    image: img7,
  },
  {
    id: 2,
    image: img8,
  },
  {
    id: 3,
    image: img9,
  },
  {
    id: 4,
    image: img10,
  },
];

const Bridges = () => {

  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  return (
    <section>
      <div data-aos="fade-up" class="relative w-full h-[320px]" id="home">
        <div class="absolute inset-0 opacity-70">
          <img
            src={img8}
            alt="Background Image"
            class="object-cover bg-no-repeat object-center w-full h-full"
          />
        </div>
        <div class="absolute inset-0 flex items-center justify-center">
          <div class="text-center">
            <h1 class="text-grey-700 font-medium text-4xl md:text-5xl leading-tight mb-2">
              الطرق و الكباري
            </h1>
            <br />
            <br />
            <Link
              to="/contactUs"
              class="px-6 py-3 bg-rose-500 text-white font-medium rounded-full hover:bg-rose-600 transition duration-200">
              تواصل معنا
            </Link>
          </div>
        </div>
      </div>

      <section data-aos="fade-up" class="py-10" id="services">
        <div class="container mx-auto px-4">
          <h2 class="text-3xl font-bold text-gray-800 mb-8 text-center">
            أعمالنا السابقة
          </h2>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
            {bridges.map((bridge, index) => (
              <div
                key={index}
                class="bg-white rounded-lg shadow-md overflow-hidden">
                <img
                  lazy="loading"
                  src={bridge.image}
                  alt="wheat flour grinding"
                  class="w-full h-64 object-cover"
                />
                <div class="p-6 text-center">
                  <h3 class="text-xl font-medium text-gray-800 mb-2">
                    {bridge.title}
                  </h3>
                  <button id="btn" class="text-gray-700 mt-5 text-base">
                    <a
                      href="tel:+201129494349"
                      className="ml-10 bg-slate-200 hover:bg-slate-300 px-6 p-1 text-xl text-gray-950 rounded-md">
                      اتصل بنا
                    </a>
                    <a
                      href="https://wa.me/+201129494349"
                      className="bg-rose-500 hover:bg-rose-600 px-6 p-1 text-xl text-gray-950 rounded-md">
                      واتساب
                    </a>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section data-aos="fade-up" class="bg-gray-100" id="aboutus">
        <div class="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div class="max-w-lg">
              <h2
                style={{ lineHeight: "1.4" }}
                class="text-3xl font-bold text-gray-800 mb-8 text-center">
                شركة الصعيدي للمقاولات العامة والتوريدات
              </h2>
              <p class="mt-4 text-gray-600 text-lg">
                شركة الصعيدي للمقاولات العامة هي شركة متخصصة في تنفيذ مشاريع
                الطرق والكباري في مختلف أنحاء البلاد. تأسست الشركة على يد مجموعة
                من المهندسين والمقاولين ذوي الخبرة العالية في مجال الإنشاءات
                والبنية التحتية، وقد حققت سمعة مرموقة بفضل الجودة العالية
                والخدمة الممتازة التي تقدمها.
              </p>
              <br />
              <p class="mt-4 text-gray-600 text-lg">
                بفضل التزامها بالجودة والابتكار، استطاعت شركة الصعيدي للمقاولات
                العامة أن تكون جزءًا من العديد من المشاريع الكبيرة والمهمة التي
                تسهم في تطوير البنية التحتية وتعزيز الاقتصاد الوطني.
              </p>
            </div>
            <div class="mt-12 md:mt-0">
              <img
                src={img4}
                alt="About Us Image"
                class="object-cover rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" class="text-gray-700 body-font" id="gallery">
        <div class="flex justify-center text-3xl font-bold text-gray-800 text-center py-10">
          اعمالنا لا تنتهي{" "}
          <MdOutlineWorkspacePremium className="text-4xl text-rose-600" />
        </div>

        <div class="grid grid-cols-1 place-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
          {gallery.map((item, index) => (
            <div key={index} class="group relative">
              <img
                lazy="loading"
                src={item.image}
                alt="Image"
                class="aspect-[2/3] h-80 object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-105"
              />
            </div>
          ))}
        </div>
      </section>
    </section>
  );
};

export default Bridges;
