import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Home from "./Pages/Home/Home";
import Services from "./Pages/Services/Services";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import FloatingBtn from "./Components/floating-button/FloatingBtn";
import Buildings from "./Pages/DetailsOfLinks/Buildings/Buildings";
import Bridges from "./Pages/DetailsOfLinks/Bridges/Bridges";
import Infrastructure from "./Pages/DetailsOfLinks/Infrastructure/Infrastructure";
import Process from "./Pages/DetailsOfLinks/Process/Process";
import Treatments from "./Pages/DetailsOfLinks/Treatments/Treatments";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <div className="loading-container">
          <svg viewBox="0 0 240 240" height="240" width="240" className="pl">
            <circle
              stroke-linecap="round"
              stroke-dashoffset="-330"
              stroke-dasharray="0 660"
              stroke-width="20"
              stroke="#000"
              fill="none"
              r="105"
              cy="120"
              cx="120"
              className="pl__ring pl__ring--a"></circle>
            <circle
              stroke-linecap="round"
              stroke-dashoffset="-110"
              stroke-dasharray="0 220"
              stroke-width="20"
              stroke="#000"
              fill="none"
              r="35"
              cy="120"
              cx="120"
              className="pl__ring pl__ring--b"></circle>
            <circle
              stroke-linecap="round"
              stroke-dasharray="0 440"
              stroke-width="20"
              stroke="#000"
              fill="none"
              r="70"
              cy="120"
              cx="85"
              className="pl__ring pl__ring--c"></circle>
            <circle
              stroke-linecap="round"
              stroke-dasharray="0 440"
              stroke-width="20"
              stroke="#000"
              fill="none"
              r="70"
              cy="120"
              cx="155"
              className="pl__ring pl__ring--d"></circle>
          </svg>
          <p>...Loading</p>
        </div>
      ) : (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route path="/aboutUs" element={<AboutUs />}></Route>
            <Route path="/contactUs" element={<ContactUs />}></Route>
            <Route path="/buildings" element={<Buildings />}></Route>
            <Route path="/bridges" element={<Bridges />}></Route>
            <Route path="/infrastructure" element={<Infrastructure />}></Route>
            <Route path="/process" element={<Process />}></Route>
            <Route path="/treatments" element={<Treatments />}></Route>
          </Routes>
          <FloatingBtn />
            <Footer />
          </>
      )}
    </div>
  );
}

export default App;
